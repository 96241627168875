import {ExportToCsv} from "export-to-csv-fix-source-map";

export class CsvHelper {
  static downloadCsv<T>(fileName:string, data:T[]) {
    const csv = new ExportToCsv({
      useKeysAsHeaders: true,
      filename:fileName
    });
    csv.generateCsv(data.map(rowIn=>{
      const row = Object.assign({}, rowIn) as any;
      for (const k of Object.keys(row)) {
        const val = row[k];
        if (Array.isArray(val)) {
          row[k] = val.length>0 ? JSON.stringify(val): '[]';
        }
      }
      return row;
    }));
  }
}
