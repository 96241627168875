import {ActivationAlgo} from "../interface";
import ComboCond = ActivationAlgo.ComboCond;

export class ActivationAlgoHelper {
    public static compareCriteria(comboVal:ComboCond[]|number|number[], symbolVal:number) {

        // @ts-ignore
        if (comboVal?.length && Array.isArray(comboVal)) {
            // @ts-ignore
            if (comboVal[0]?.compare) {
                if (!ActivationAlgoHelper.compareCond(comboVal as ComboCond[], symbolVal)) {
                    return false;
                }
            } else {
                // number[]
                if (!comboVal.includes(symbolVal as any)) {
                    return false;
                }
            }
        } else {
            if (comboVal!==symbolVal) {
                return false;
            }
        }
        return true;
    }

    public static compareCond(conds:ActivationAlgo.ComboCond[], val:number|undefined|null) {
        if (!conds || conds.length===0) {
            return true;
        }
        if (val==null) {
            return false;
        }
        let currResult:boolean = null as any;
        for (const [index,c] of conds.entries()) {
            const condOk = (c.compare==='>=' && val>=c.value)
                           || (c.compare==='<=' && val<=c.value)
                           || (c.compare==='>' && val>c.value)
                           || (c.compare==='<' && val<c.value);
            const cond = c.cond || 'OR';
            if (index===0) {
                currResult = condOk;
            } else {
                currResult = (cond==='OR' && (currResult||condOk)) || (currResult&&condOk);
            }
        }
        return currResult;
    }
}
