export enum SignalAlertPerfTypeEnum {
    COMBO                  = 1, // combo report
    YESTERDAY              = 2, // yesterday rep
    APERF                  = 3, // good alert score
    STDAY                  = 4,
    IND                    = 5, // indicator(criteria)
    REPEAT_WINNER          = 6,
    STDAY_3CRIT            = 7,
}

export class SignalAlertPerfTypeEnumHelper {
    protected static readonly namesMap: Map<SignalAlertPerfTypeEnum, string> = new Map([
        [SignalAlertPerfTypeEnum.COMBO,         'COMBO'],
        [SignalAlertPerfTypeEnum.YESTERDAY,     'YEST'],
        [SignalAlertPerfTypeEnum.APERF,         'APERF'],
        [SignalAlertPerfTypeEnum.STDAY,         'STDAY'],
        [SignalAlertPerfTypeEnum.STDAY_3CRIT,   'STDAY+3'],
        [SignalAlertPerfTypeEnum.IND,           'IND'],
        [SignalAlertPerfTypeEnum.REPEAT_WINNER, 'RPWIN'],
    ]);

    public static names() {
        return this.namesMap;
    }
}
