import React, {useContext, useRef} from "react";
import {ActivationAlgo, ISignalAlertActivation as Entity, SignalAlertPerfTypeEnum} from "predictagram-lib";
import {adminApiServiceCommon} from "../../../services/AdminApiService";
import {useApiServiceState} from "../../../_hooks/useApiServiceState";
import {Spinner} from "../../common/Spinner";
import {getCoreRowModel, getSortedRowModel, SortingState, useReactTable} from "@tanstack/react-table";
import {ReactTableHelper} from "../../../_utils/ReactTableHelper";
import {DataGrid} from "../common/DataGrid";
import {useNavigate} from "react-router-dom";
import {UrlHelper} from "../../../_utils/UrlHelper";
import {MessengerContext, Severity} from "../../common/messenger";
import {ArrowClockwise} from "react-bootstrap-icons";
import StatusEnumHelper = ActivationAlgo.StatusEnumHelper;
import StatusEnum = ActivationAlgo.StatusEnum;

export const AdminActivationCombos: React.FunctionComponent = () => {
  const msgrContext = useContext(MessengerContext);
  const navigate = useNavigate();
  const refStatusId = useRef(StatusEnum.ACTIVE);
  const supplier = async() => {
    const data = await adminApiServiceCommon.getActivationCombos({
      signalAlertActivationStatusIds: refStatusId.current ? [refStatusId.current]:undefined as any,
    });
    // @TODO: only this type is supported in UI form
    return data.filter(v=>v.algoTypeId===SignalAlertPerfTypeEnum.IND);
  }

  const statuses = Array.from(StatusEnumHelper.getNames().entries()).map(([s,name])=>{
    return {label:name,value:s}
  });

  const deleteItem = async(id:number)=>{
    if (window.confirm(`Delete item #${id}?`)) {
      const res = await adminApiServiceCommon.deleteActivationComboById(id);
      msgrContext.setMessage({ body: res?'Removed':'Not Removed' }, true);
      if (res) {
        reload();
      }
    }
  }
  const setStatus = async(id:number,statusId: ActivationAlgo.StatusEnum)=>{
    // @TODO: refactor the  wrapper
    try {
      await adminApiServiceCommon.updateActivationComboById(id,{statusId:statusId});
      return true;
    } catch (e: any) {
      msgrContext.setMessage({body: e.message}, true, Severity.FATAL);
    }

  }

  const [sorting, setSorting] = React.useState<SortingState>([])
  const {items, state, setSupplier, reload} = useApiServiceState<Entity[]>(()=>supplier());
  const columnHelper = ReactTableHelper.createHelper<Entity>();
  const columns = [
    columnHelper.columnBuilder('id', {header: 'ID'}).number().build(),
    columnHelper.columnBuilder('statusId', {
      header: 'Status',
      cell: (info)=>{
        const id = parseInt(info.cell.row.getValue('id'));
        return (
          <select defaultValue={info.getValue()} onChange={(e)=>setStatus(id, parseInt(e.target.value) as any)}>
            {statuses.map(s=><option value={s.value}>{s.label}</option>)}
          </select>
            // <DropDownGeneric label={''} value={info.getValue()} name={'statusId'} options={statuses} onChange={(v)=>{setStatus(id, v);return true}}/>
        )
      }
    }).build(),
    columnHelper.columnBuilder('name', {header: 'Name'}).build(),

    columnHelper.columnBuilder('buttons', {
      header: '',
      cell: (info)=>{
        const id = parseInt(info.cell.row.getValue('id'));
        const url = UrlHelper.getAdminActivationCombo(id);
        return (<>
          <div className="d-flex gap-1">
            <div className="d-flex flex-column gap-3"><button className="btn btn-primary" onClick={()=>navigate(url)}>Edit</button></div>
            <div className="d-flex flex-column gap-3"><button className="btn btn-primary" onClick={()=>deleteItem(id)}>Delete</button></div>
          </div>
          </>
        )
      }
    }).build(),
  ];


  const table = useReactTable<Entity>({
    data: items || [],
    columns: columns as any,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {sorting},
  });

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <div className="page-title">Activation Combos</div>
        <button className="btn btn-primary mt-2" onClick={() => navigate(UrlHelper.getAdminActivationCombo())}>Create New</button>
      </div>
      <div className="d-flex align-items-center gap-4 justify-content-center">
        <div>
          Status:
          <select defaultValue={refStatusId.current} onChange={(e)=>refStatusId.current=parseInt(e.target.value) as any}>
            <option value=''>All</option>
            {statuses.map(s=><option value={s.value}>{s.label}</option>)}
          </select>
        </div>
        <div><button className="btn btn-primary mt-2" onClick={() => reload()}><ArrowClockwise /></button></div>
      </div>
      <div>&nbsp;</div>
      {state.isLoading() && <Spinner />}
      {state.isError() && <div>{state.error()?.message}</div>}
      {state.isLoaded() &&
       <DataGrid table={table}/>
      }
    </>
  );
};


