import {Helper} from "_utils";
import {ISignalAlert} from "components/admin/analysis/strategy-profit/ISignalAlert";
import {TradeSetupFilterOptions} from "components/admin/autotrade/TradeSetupForm";
import {PresetDateRange} from "components/admin/autotrade/filters/PresetDateRangeFilter";
import {AnalysisFilters} from "components/common/analysis/analysis.filters";
import {
  AutoTradePositionTypeEnum,
  AutoTradePositionTypeEnumHelper,
  AutoTradeSecurityTypeEnum,
  AutoTradeSecurityTypeEnumHelper,
  AutoTradeSetupStatusEnum,
  AutoTradeStatusEnum,
  DateHelper,
  StockDirectionType as DirectionType,
  IAutoTrade,
  IAutoTradeIbkrOrder,
  IAutoTradeSetup,
  IBKRSide,
  ITradeTemplate,
  NewYorkTz,
  OptionType,
  StockDirectionType as SignalDirectionType,
  SignalRepeatType,
  StockHelper,
  TriggerExitTypeEnumHelper, AutoTradeHelper
} from "predictagram-lib";
import {adminAutoTradeSetupService, adminSignalAlertApiService, IAutoTradeLivePrice} from "services/AdminApiService";
import {TradeSetupModel} from "./trade-setup.model";
import {SignalAlertHelper} from "../_utils/signal-alert.helper";

export interface ITradeLivePrice extends IAutoTradeLivePrice {
  id: number,
}

export type Comparator = (a: IAutoTrade,b: IAutoTrade, currentDirection: "asc" | "desc") => number
export type TableColumn = {
  name: string | undefined, 
  title: string | JSX.Element, 
  comparator?: Comparator | undefined
}

export interface ISortColumn {
  tableColumn: TableColumn,
  direction: "asc" | "desc",
}

export interface IQuantity {
  active: number,
  closed: number,
  total: number,
  other: number,
}

export class TradeModel {

  static compareHelper (a: string | number | null, b: string | number | null, currentDirection: "asc" | "desc")  {

    if ((a === null || a === '') && (b === null || b === '')) {
      return 0;
    }

    if (a === null || a.toString() === "" || a.toString() === "undefined") {
      return 1;
    }

    if (b === null || b.toString() === "" || b.toString() === "undefined") {
      return -1;
    }
 
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b * (currentDirection === "asc" ? 1 : -1)
    }

    const result = a.toString().localeCompare(b.toString(), 'en-US', {numeric: true});
    if (result < 0) {
      return currentDirection === "asc" ? -1 : 1;
    }
  
    if (result > 0) {
      return currentDirection === "asc" ? 1 : -1;
    }
    return 0;  
  }

  static getOpenedFilterFromPreset(presetDateRangeFilter: PresetDateRange | undefined) {
    if (!presetDateRangeFilter) {
      return({ startTime: undefined, endTime: undefined });
    }

    if (presetDateRangeFilter?.toString() === PresetDateRange.TODAY.toString()) {
      return({ startTime: StockHelper.fullHours().start.getTimeSec(), endTime: StockHelper.fullHours().end.getTimeSec() });
    }

    if (presetDateRangeFilter?.toString() === PresetDateRange.YESTERDAY.toString()) {
      const yesterday = StockHelper.findPreviousTradingDay(new Date());
      return({
        startTime: StockHelper.fullHours(yesterday).start.getTimeSec(),
        endTime: StockHelper.fullHours(yesterday).end.getTimeSec()
      });
    }

    if (presetDateRangeFilter?.toString() === PresetDateRange.THIS_WEEK.toString()) {
      const monday = Helper.getStartDateOfWeek(1, new Date());
      const friday = Helper.getStartDateOfWeek(5, new Date());
      return({ startTime: StockHelper.fullHours(monday).start.getTimeSec(), endTime: StockHelper.fullHours(friday).end.getTimeSec() });
    }

    if (presetDateRangeFilter?.toString() === PresetDateRange.LAST_WEEK.toString()) {
      const monday = Helper.getStartDateOfWeek(1, new Date(), 1);
      const friday = Helper.getStartDateOfWeek(5, new Date(), 1);
      return({ startTime: StockHelper.fullHours(monday).start.getTimeSec(), endTime: StockHelper.fullHours(friday).end.getTimeSec() });
    }

    if (presetDateRangeFilter?.toString() === PresetDateRange.THIS_MONTH.toString()) {
      const startOfMonth = Helper.getFirstOfMonth();
      const lastOfMonth = Helper.getLastDateOfMonth();
      return({ startTime: StockHelper.fullHours(startOfMonth).start.getTimeSec(), endTime: StockHelper.fullHours(lastOfMonth).end.getTimeSec() });
    }    

    if (presetDateRangeFilter?.toString() === PresetDateRange.LAST_30.toString()) {
      const today = new Date();
      const thiryDaysAgo = DateHelper.prevNextDateUs(today, -30);
      return({ startTime: StockHelper.fullHours(thiryDaysAgo).start.getTimeSec(), endTime: StockHelper.fullHours(today).end.getTimeSec() });
    }    


  }

  static signalRepeatType = new Map([
    [SignalRepeatType.PRIMARY, 'Primary'],
    [SignalRepeatType.REPEAT, 'Repeat'],
  ]);
  
  static signalDirectionType = new Map([
    [SignalDirectionType.UP, 'UP'],
    [SignalDirectionType.DOWN, 'DOWN'],
  ]);

  static oppositeDir = new Map([[false, 'No'], [true, 'Yes']]);

  static signalRepeatIntervalSecs = AnalysisFilters.SignalRepeatTypes;

  static calcRealizedProfitLossTradeIbkr(trade: IAutoTrade) {
    return AutoTradeHelper.calcProfitLoss({
      open:trade.ibkr.openPriceAvg,
      close:trade.ibkr.closePriceAvg,
      quantity:trade.ibkr.closedQuantity,
      multiplier:this.multiplier(trade),
      isLong: trade.ibkr.openOrders?.filter(v=>v.orderSide===IBKRSide.BUY).length>0,
    })
    // return this.calcProfitLoss({
    //   open:trade.ibkr.openPriceAvg,
    //   close:trade.ibkr.closePriceAvg,
    //   quantity:trade.ibkr.closedQuantity,
    //   multiplier:this.multiplier(trade),
    //   isLong: trade.ibkr.openOrders?.filter(v=>v.orderSide===IBKRSide.BUY).length>0,
    // });
  }

  static calcUnrealizedProfitLossIbkr (trade: IAutoTrade, currentPrice: number) {
    return AutoTradeHelper.calcProfitLoss({
      open:trade.ibkr.openPriceAvg,
      close:currentPrice,
      quantity: trade.ibkr.openedQuantity-trade.ibkr.closedQuantity,
      multiplier: this.multiplier(trade),
      isLong: trade.ibkr.openOrders?.filter(v=>v.orderSide===IBKRSide.BUY).length>0,
    });
    // return TradeModel.calcProfitLoss({
    //   open:trade.ibkr.openPriceAvg,
    //   close:currentPrice,
    //   quantity: trade.ibkr.openedQuantity-trade.ibkr.closedQuantity,
    //   multiplier: this.multiplier(trade),
    //   isLong: trade.ibkr.openOrders?.filter(v=>v.orderSide===IBKRSide.BUY).length>0,
    // });
  }

  // protected static calcProfitLoss(p:{open: number, close: number, quantity: number, multiplier:number, isLong:boolean}) {
  //   const amount = (p.close - p.open) * p.quantity * p.multiplier;
  //   return p.isLong ? amount : -amount;
  // }

  static getOptionTypeFromDirectionTypes = (directionTypes: DirectionType[] | undefined) => {
    if (directionTypes && directionTypes.length === 1 && directionTypes[0] === DirectionType.DOWN) return OptionType.PUT;
    return OptionType.CALL;
  }

  static async createTradeSetupFromSignalAlert(signalAlertId: number, statusId=AutoTradeSetupStatusEnum.PAUSED) {

    const defaultValues = TradeSetupModel.optionsInitialValues;
    const signalAlert: ISignalAlert = await adminSignalAlertApiService.getById(signalAlertId, true);
    const analysisSetup: Partial<TradeSetupFilterOptions> = signalAlert.data;
    const tradeTemplate: ITradeTemplate = {
      maxExpirationDays: defaultValues.maxExpirationDays as number,
      minExpirationDays: defaultValues.minExpirationDays as number,
      strikePriceOffset: defaultValues.strikePriceOffset as number,
      optionType: this.getDefaultOptionType(analysisSetup),
      ibkr: {
        side: IBKRSide.BUY,
        orderType: defaultValues.ibkrOrderType,
      }
    }
    const newTradeSetup: IAutoTradeSetup = {
      statusId: statusId,
      tradeSecurityTypeId: AutoTradeSecurityTypeEnum.OPTION,
      name: signalAlert.name,
      signalAlertId: signalAlert.id,
      maxActiveTrades: 2,
      quantityPerTrade: 1,
      maxTradesPerDay: 10,
      tradeTemplate: tradeTemplate,
      analysisSetup: analysisSetup
    } as IAutoTradeSetup;

    return await adminAutoTradeSetupService.create(newTradeSetup);
  }

  static getDefaultOptionType (setupOptions: Partial<TradeSetupFilterOptions>) : OptionType {
    const openTriggerDirectionTypes = setupOptions.openTriggerDirectionTypes && setupOptions.openTriggerDirectionTypes.length > 0 ? setupOptions.openTriggerDirectionTypes[0] : undefined;
    if (openTriggerDirectionTypes === DirectionType.DOWN) { 
      return OptionType.PUT;
    }

    const openSignalSetup = setupOptions.openSignalSetup;

    const openSignalDirectionType = (openSignalSetup?.signals 
        && openSignalSetup.signals 
        && openSignalSetup.signals[0].directionTypes
        && openSignalSetup.signals[0].directionTypes[0]) || null;

    if (openSignalDirectionType === null ) return OptionType.CALL;

    if (openSignalDirectionType === SignalDirectionType.DOWN) return OptionType.PUT;

    return OptionType.CALL;
  }

  static getOpenPriceWeightedAverage (trades: IAutoTrade[]) {
    return 0;
    // const result = trades.reduce(
    //   (acc, trade, index, array) => {
    //     if (trade.ibkrOpenOrder ) {
    //       acc.totalWeightedValue += trade.ibkrOpenOrder.orderPrice * trade.openQuantity;
    //       acc.totalWeight += trade.openQuantity;
    //     }
    //     return acc;
    //   },
    //   {totalWeightedValue: 0, totalWeight: 0})

    // return result.totalWeightedValue / result.totalWeight;
  }

  static getClosePriceWeightedAverage (trades: IAutoTrade[]) {
    return 0;
    // const result = trades.reduce(
    //   (acc, trade, index, array) => {
    //     if (trade.ibkrCloseOrder) {
    //       acc.totalWeightedValue += trade.ibkrCloseOrder.orderPrice * trade.closeQuantity;
    //       acc.totalWeight += trade.closeQuantity;
    //     }
    //     return acc;
    //   },
    //   {totalWeightedValue: 0, totalWeight: 0})

    // return result.totalWeightedValue / result.totalWeight;
  }

  static getTradesProfitLossIbkr (trades: IAutoTrade[]) {
    const data = trades.reduce((total, trade) => {
      return {
        profitLoss: total.profitLoss + AutoTradeHelper.calcRealizedProfitLossTradeIbkr(trade),
        invested: total.invested + (trade.ibkr.openPriceAvg * trade.ibkr.closedQuantity * this.multiplier(trade)) ,
        // finalValue: finalValue + (trade.ibkr.closePriceAvg * trade.ibkr.closedQuantity)
      }
    }, {
      profitLoss: 0,
      invested: 0,
    });
    return {
      realized: {
        profitLoss: data.profitLoss,
        invested: data.invested,
      },
      // unrealized: {
      //
      // }
    }
  }

  static getProfitLossPct (initialValue: number, finalValue: number) {
    return (finalValue - initialValue) / initialValue * 100;
  }

  static getTradesTotalQuantity(trades: IAutoTrade[]) {
    return trades.reduce((acc: IQuantity, trade) => {
      switch (trade.statusId) {
        case AutoTradeStatusEnum.ACTIVE:
          acc.active +=1;
          break;
        case AutoTradeStatusEnum.CLOSED:
          acc.closed += 1;
          break;
        default:
          acc.other += 1;
      }
      acc.total +=1;
      return acc;
    }, {
      active: 0,
      closed: 0,
      total: 0,
      other: 0,
    } as IQuantity);
  }

  static getSecurityName(trade: IAutoTrade) {
    if (trade.securityTypeId===AutoTradeSecurityTypeEnum.OPTION) {
      const res = this.getSecurityOptionName(trade?.ibkr?.openOrders?.[0]);
      if (res) {
        return res;
      }
    }
    return AutoTradeSecurityTypeEnumHelper.names.get(trade.securityTypeId) as string;
  }

  static getSecurityOptionName (ibkrOpenOrder: IAutoTradeIbkrOrder | undefined) {
    if (!ibkrOpenOrder) {
      return undefined;
    }
    return `${ibkrOpenOrder.orderSide} ${ibkrOpenOrder.optionName}`;
  }

  static getUrealizedProfitLossOptionTrades (trades: IAutoTrade[], livePrices: ITradeLivePrice[]) {
    const unrealizedPl = trades
      .filter(t=>t.statusId===AutoTradeStatusEnum.ACTIVE)
      .filter(t=>livePrices.find((p)=>p.id === t.id ))
      .reduce((pl, trade)=> {
        const p = livePrices.find((p)=>p.id===trade.id);
        if (p) {
          pl += TradeModel.calcUnrealizedProfitLossIbkr(trade, p.lastPrice) || 0
        }
        return pl;
      }, 0);
    return unrealizedPl;
  }

  static multiplier(trade:IAutoTrade) {
    return AutoTradeHelper.multiplier(trade);
    // return trade.securityTypeId===AutoTradeSecurityTypeEnum.OPTION?100:1;
  }

  static getClosedShares (trades:IAutoTrade[]) {
    return trades.reduce((t, trade)=> t+= (trade.statusId === AutoTradeStatusEnum.CLOSED ? trade.closeQuantity * this.multiplier(trade) : 0), 0);
  }

  static getActiveShares (trades:IAutoTrade[]) {
    return trades.reduce((t, trade)=> t+= (trade.statusId === AutoTradeStatusEnum.ACTIVE ? trade.openQuantity * this.multiplier(trade) : 0), 0);
  }

  static getActiveTotalCost (trades:IAutoTrade[]) {
    return trades.reduce((t, trade)=> t+= (trade.statusId === AutoTradeStatusEnum.ACTIVE ? 
      (trade.ibkr.openPriceAvg * trade.ibkr.openedQuantity * this.multiplier(trade))
      : 0), 0);
  }

  static getMaxTotalInvested (trades:IAutoTrade[]) {
    return trades.reduce((t, trade)=> t+= ([AutoTradeStatusEnum.ACTIVE, AutoTradeStatusEnum.CLOSED].includes(trade.statusId) ? 
      (trade.ibkr.openPriceAvg * trade.ibkr.openedQuantity * this.multiplier(trade) )
      : 0), 0);
  }

  static exportMap = (item: IAutoTrade) => {
    return {
      id: item.id,
      setupId: item.setupId,
      setupSignalAlertId: item.setupSignalAlertId,
      setupName: item.setupName,
      // stockSymbolId: item.stockSymbolId,
      stockSymbolName: item.stockSymbolName,
      signalAlertCategories: (item.setupSignalAlertCategories||[]).join(' '),
      signalAlertAlgo: SignalAlertHelper.getAlertScoreToString(item.signalAlertScore),
      securityName:TradeModel.getSecurityName(item),
      alertScore: SignalAlertHelper.getAlertScoreToString(item.signalAlertScore),
      positionType: AutoTradePositionTypeEnumHelper.names.get(item.positionTypeId),
      createdAtUs: NewYorkTz.format(new Date(item.createdAt * 1000)).monthNameDateTimeSec(),
      openedOnUs: NewYorkTz.format(new Date(item.openAt * 1000)).monthNameDateTimeSec(),
      closedOnUs: item.closeAt ? NewYorkTz.format(new Date(item.closeAt * 1000)).monthNameDateTimeSec() : '',
      closeTrigger: TriggerExitTypeEnumHelper.names.get(item.closeTriggerTypeId),

      openPrice: item.openPrice,
      closePrice: item.closePrice,
      profitLoss: item.closeQuantity ? AutoTradeHelper.calcProfitLoss({
        isLong:item.positionTypeId===AutoTradePositionTypeEnum.LONG,
        multiplier: 1,
        quantity: item.closeQuantity,
        close: item.closePrice,
        open: item.openPrice,
      }) : '',


      ibkrProfitLoss: AutoTradeHelper.calcRealizedProfitLossTradeIbkr(item),
      ibkrOpenPrice: item.ibkr?.openPriceAvg,
      ibkrClosePrice: item.ibkr?.closePriceAvg,
      ibkrOpenedQuantity: item.ibkr?.openedQuantity,
      ibkrClosedQuantity: item.ibkr?.closedQuantity,
    }
  }

  // static getSignalIdFromTradeSetupName = (tradeSetupName: string) => {
  //   const match = tradeSetupName.match(/\b\d+\b/);
  //   return match ? parseInt(match[0]) : undefined
  // }

  static getTradeSetupFullTitle = (setupName:string, alertId:number|null, parentAlertId:number|null=null) => {
    return `${alertId?(alertId + ' ' + (parentAlertId?`(${parentAlertId}) `: '')): ''}${setupName}`;
  }
}
