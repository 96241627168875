import React from "react";
import {DropDownGeneric, DropDownType} from "./form/DropDownGeneric";
import {SignalAlertCategoryEnum, SignalAlertCategoryEnumHelper} from "predictagram-lib";



export const SignalAlertCategoriesDropDown: React.FunctionComponent<Partial<DropDownType<SignalAlertCategoryEnum,string>>> = ({
  name = 'signalAlertCategories',
  label = <div className="fw-bold">Alert Cats</div>,
  showEmpty = false,
    ...rest
})=> {
  const options = Array.from(SignalAlertCategoryEnumHelper.names().entries()).map((v,t)=>{
    return {
      label: v[0],
      value: v[1],
    }
  });
  if (showEmpty) {
    options.unshift({label:'All' as any, value: null as any});
  }
  return (
    <DropDownGeneric {...rest} options={options} label={label} name={name} /*onChange={onChange}*/ multiple={rest.multiple!=null?rest.multiple:true} />
  );
}

