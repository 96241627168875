import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  RowData,
  SortingState,
  Table,
  useReactTable
} from "@tanstack/react-table";
import {SortDown, SortUp} from "react-bootstrap-icons";
import React from "react";
import {DropDownType} from "../../common/form/DropDownGeneric";

interface Options<T> {
  table: Table<T>
}
interface OptionsColumns<T,V> {
  columns: T[],
  data: V[],
  sorting?: boolean,
}

export function DataGrid<T>(props: Options<T>) {
  const table = props.table;
  return (
    <table className="table table-hover table-striped">
      <thead>
      {table.getHeaderGroups().map((headerGroup,index) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header,index) => {
              return (
                <th key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()}
                    className={[
                      ...header.column.columnDef.meta?.classNames||[],
                      header.column.getCanSort()? 'cursor-pointer select-none': ''
                    ].join(' ')}
                >
                  {header.isPlaceholder
                   ? null
                   : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  {{
                     asc: <SortUp />,
                     desc: <SortDown />,
                   }[header.column.getIsSorted() as string] ?? null}
                </th>
              )
            }
          )}
        </tr>
      ))}
      </thead>
      <tbody>
      {table.getRowModel().rows.map(row => (
        <tr key={row.id}>
          {row.getVisibleCells().map(cell => (
            <td key={cell.id} className={cell.column.columnDef.meta?.classNames?.join(' ')}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
      </tbody>
      <tfoot>
      {table.getFooterGroups().map(footerGroup => (
        <tr key={footerGroup.id}>
          {footerGroup.headers.map(header => (
            <th key={header.id} className={header.column.columnDef.meta?.classNames?.join(' ')}>
              {header.isPlaceholder
               ? null
               : flexRender(
                  header.column.columnDef.footer,
                  header.getContext()
                )}
            </th>
          ))}
        </tr>
      ))}
      </tfoot>
    </table>
  )
}



export function DataGridTableColumns<T,V>(props: OptionsColumns<T,V>) {
  const [sorting, setSorting] = React.useState<SortingState>([])

  const table = useReactTable({
    data: props.data,
    columns: props.columns as any,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    // getGroupedRowModel: getGroupedRowModel(),
    onSortingChange: props.sorting ? setSorting: undefined,
    state: {
      sorting: props.sorting ? sorting : undefined,
    },
    // debugTable: true,
  });

  return (
    <DataGrid table={table} />
  )
}
