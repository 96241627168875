import React, {useContext, useEffect, useRef, useState} from "react";
import {
  ActivationAlgo,
  AdminReportType,
  ISignalAlertActivation,
  IStockSymbolAdmin,
  MathEx,
  StockDirectionType,
} from "predictagram-lib";
import {Form, Formik} from "formik";
import {FieldWithError} from "components/common";
import {Spinner} from "components/common/Spinner";
import {MessengerContext, Severity} from "../../common/messenger";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ApiStateImpl, IApiState} from "../../../_constants/APIState";
import {adminApiServiceCommon} from "../../../services/AdminApiService";
import {DropDownGeneric} from "../../common/form/DropDownGeneric";
import {SignalAlertCategoriesDropDown} from "../../common/SignalAlertCategoriesDropDown";
import {UrlHelper} from "../../../_utils/UrlHelper";
import {UtilsHelper} from "predictagram-lib/dist/utils/utils.helper";
import Criteria = ActivationAlgo.Criteria;
import ComboCondCompare = ActivationAlgo.ComboCondCompare;
import IReport = ActivationAlgo.IReport;
import {ActivationAlgoHelper} from "predictagram-lib/dist/analysis/activation.algo";
import {CsvHelper} from "../../../_utils/CsvHelper";
import {AdminReportTypeHelper} from "predictagram-lib/dist/dict/admin-report-type.enum";
import IComboIndicator = ActivationAlgo.IComboIndicator;

export enum SubmitModeEnum {
  CREATE = 1,
  UPDATE = 2,
  SAVE_AS_NEW = 3,
}
interface Options extends ISignalAlertActivation {

}

export const ActivationComboPage: React.FunctionComponent = () => {

  const msgrContext = useContext(MessengerContext);

  const [searchParams] = useSearchParams();
  const itemId = searchParams.get('id')!==null ? parseInt(searchParams.get('id') as any): null;

  const [initialValues, setInitialValues] = useState<Partial<Options>>({
  });

  const navigate = useNavigate();

  const [state, setState] = useState<IApiState>(ApiStateImpl.IDLE);

  useEffect(() => {
    if (itemId === null) {

    } else {
      (async () => {
        const item = await adminApiServiceCommon.getActivationComboById(itemId);
        setInitialValues(item);
      })()
    }
  }, [itemId])

  const submitWrap = async (searchOptions: Options, submitMode: SubmitModeEnum) => {
    try {
      await submit(searchOptions, submitMode);
    } catch (e: any) {
      msgrContext.setMessage({ body: e.message }, true, Severity.FATAL);
      setState(ApiStateImpl.error(e));
    }
  }


  const submit = async (dataIn: Options, submitMode: SubmitModeEnum) => {

    const payload = {
      name: dataIn.name,
      data: dataIn.data,
    }

    // new form or save as
    if ([SubmitModeEnum.CREATE,SubmitModeEnum.SAVE_AS_NEW].includes(submitMode)) {
      const result = await adminApiServiceCommon.createActivationComboById(payload);
      msgrContext.setMessage({ body: 'Created #' + result.id }, true, Severity.NORMAL);
      navigate(UrlHelper.getAdminActivationCombo(result.id));
      return;
    } else
    // update existing
    if (submitMode === SubmitModeEnum.UPDATE) {
      if (itemId === null) {
        msgrContext.setMessage({ body: 'Could not update. Missing id' }, true, Severity.FATAL);
        return;
      }
      const result = await adminApiServiceCommon.updateActivationComboById(itemId, payload);
      msgrContext.setMessage({ body: 'Updated #' + result.id }, true, Severity.NORMAL);
    }

  }

  return (
    <div className="strategy-profit mt-3 d-flex justify-content-center">
      <div className={`d-flex flex-column justify-content-center align-items-start gap-3 'd-block'}`}>
        <ActivationComboForm  initialValues={initialValues} onClick={submitWrap} isEditing={itemId !== null} />
      </div>
    </div>
  );
};


const valuesScores = [
  {label:'All', value: null},
  ...(()=>{
    const dataRange = [.05, .1, .15, .2, .25, .3, .4, .5, .6, .7, .8, .9, 1.0];
    return [...dataRange, 0, ...dataRange.map(v=>v*-1)].sort((a,b)=>b-a).map(i=>{
      return {label: `${MathEx.round(i, 2)}%`, value: MathEx.round(i/100,4) as any}
    })
  })(),
];
const valuesTrades = [
  {label:'All', value: null},
  ...(()=>{
    const data = [];
    for (let i = 1;i<=50;i+=1) {
      data.push({label: `${i}`, value: i as any});
    }
    return data;
  })(),
];

const FormCondition:React.FunctionComponent<{name: string, label: string, values:{label:string,value:any}[]}> =(
  {name, label, values}
) => {

  const opts = [
    {label: 'All', value: null},
    ...Object.values(ComboCondCompare).map(v=>{return {label:v,value:v}})
  ];

  return (
    <div style={{border:"1px solid #000"}}>
      {label}
      <div className="d-flex align-items-end">
        <div className="d-flex flex-column gap-3" style={{width:"30px"}}>
        </div>
        <div className="d-flex flex-column gap-4" style={{width:'100px'}}>
          <DropDownGeneric label={''} name={`${name}[0].compare`} options={opts} />
        </div>
        <div className="d-flex flex-column gap-3"  style={{width:'120px',marginLeft:'20px'}}>
          <DropDownGeneric label={'Value'} name={`${name}[0].value`} options={values} />
        </div>
      </div>
      {/*{state &&*/}
         <div className="d-flex align-items-end">
           <div className="d-flex flex-column gap-3" style={{width:"30px"}}>
             OR {/* OR is default in backend*/}
             {/*<input type="hidden" readOnly={true} value='OR' size={2}  name={`${name}[1].cond`} />*/}
           </div>
           <div className="d-flex flex-column gap-3" style={{width:'100px'}}>
             <DropDownGeneric label={''} name={`${name}[1].compare`} options={opts} />
           </div>
           <div className="d-flex flex-column gap-3"   style={{width:'120px',marginLeft:'20px'}}>
             <DropDownGeneric label={'Value'} name={`${name}[1].value`} options={values} />
           </div>
         </div>
      {/*}*/}

    </div>
  )
}

export const ActivationComboForm: React.FunctionComponent<{
  initialValues: Partial<Options>,
  onClick: (search: any, submitMode: SubmitModeEnum) => Promise<void>,
  isEditing: boolean,
}> = ({ initialValues, onClick, isEditing}) => {
  const msgrContext = useContext(MessengerContext);
  const inputValues = UtilsHelper.createCopy(initialValues);
  const defaultValues: Partial<Options> = {};
  const [submitMode, setSubmitMode] = useState<SubmitModeEnum>(SubmitModeEnum.CREATE);

  const [filterResult, setFilterResult] = useState<{scoreWAvg:number,trades:number,uniqDays:number}|null>(null);

  const actionType = useRef<'analyze'|'download'|'submit'>('submit');

  const scoreFieldList:string[] = [
    'scoreLast150',
    'scoreLast90',
    'scoreLast30',
    'scoreLast7',
    'scoreYesterday',
    'scoreBeforeYesterday',
    'aperfScore',
  ];

  const tradeFieldList:string[] = [
    'tradesLast30',
    'tradesProfitLast14'
  ]
  const reportType = AdminReportType.SIGNAL_ALERT_INDICATOR_DETAILS;
  const filterReport = async(data:Options)=>{
    const reportsList = await adminApiServiceCommon.getSavedReports({adminReportTypeIds:[reportType]});
    if (!reportsList.length) {
      msgrContext.setMessage({body:'report not found'});
      return;
    }
    const id = reportsList[0].id;
    const reportData = (await adminApiServiceCommon.getSavedReport(id))?.data as IReport[];
    if (!reportData) {
      msgrContext.setMessage({body:'report not found'});
      return;
    }
    const combo = data.data as IComboIndicator;
    // console.debug({combo});
    const filteredData = reportData.filter(row=>{
      if (combo.alertIds?.length) {
        if (!combo.alertIds.includes(row.alertId)) {
          return false;
        }
      }
      if (combo.alertHours?.length) {
        const hours = row.hoursAtDate as number[] || [];
        let pass = false;
        for (const comboHour of combo.alertHours) {
          if (hours.includes(comboHour)) {
            pass = true;
            break;
          }
        }
        if (!pass) {
          return false;
        }
      }

      if (combo.alertDirection && combo.alertDirection!==row.alertDir) {
        return false;
      }
      if (combo.symbolIds?.length) {
        if (!combo.symbolIds.includes(row.symbolId)) {
          return false;
        }
      }

      if (combo.alertCategory?.length) {
        for (const cCat of combo.alertCategory) {
          if (!row.alertCats.length || !row.alertCats?.includes(cCat)) {
            return false;
          }
        }
      }

      for (const scoreName of scoreFieldList) {
        if (scoreName in combo) {
          // @ts-ignore
          const comboVal = combo[scoreName];
          // @ts-ignore
          const rowVal = row[scoreName];
          if (!rowVal) {
            return false;
          }
          if (!ActivationAlgoHelper.compareCond(comboVal, rowVal)) {
            return false;
          }
        }
      }

      for (const tradeName of tradeFieldList) {
        if (tradeName in combo) {
          // @ts-ignore
          const comboVal = combo[tradeName];
          // @ts-ignore
          const rowVal = row[tradeName];
          if (rowVal<comboVal) {
            return false;
          }
        }
      }

      for (const critName of Object.values(Criteria)) {
        if (critName in combo) {
          // @ts-ignore
          const comboVal = combo[critName];
          // @ts-ignore
          const rowVal = row[critName];
          if (!ActivationAlgoHelper.compareCriteria(comboVal, rowVal)) {
            return false;
          }
        }
      }
      return true;
    });

   return {filteredData, reportId: id};
  }

  const downloadReport = async(dataIn:Options)=>{
    const data = await filterReport(dataIn);
    if (!data) {
      return;
    }
    const fileName = `report-${AdminReportTypeHelper.getNames().get(reportType)}-${data?.reportId}-filtered`;
    CsvHelper.downloadCsv(fileName, data.filteredData);
  }

  const analyzeReport = async(dataIn:Options)=>{
    const data = await filterReport(dataIn);
    if (!data) {
      return;
    }
    let scoreTradesSum = 0;
    let trades = 0;
    const uniqDays = new Set<string>();
    data.filteredData.forEach((row)=>{
      trades+=row.tradesAtDate;
      scoreTradesSum+=(row.tradesAtDate*row.scoreAtDate);
      uniqDays.add(row.date);
    });
    setFilterResult({
      trades: trades,
      scoreWAvg: trades ? MathEx.round(scoreTradesSum/trades, 5) : 0,
      uniqDays: uniqDays.size,
    })

  }

  useEffect(() => {
    setSubmitMode(inputValues != null ? SubmitModeEnum.UPDATE : SubmitModeEnum.CREATE)
  }, [inputValues])

  const [ symbols, setSymbols ] = useState<IStockSymbolAdmin[]>([]);
  useEffect(()=>{
    const _load = async () => {
      const symbols = await adminApiServiceCommon.getSymbols();
      setSymbols(symbols);
    }
    _load();
  }, []);

  const initVals = Object.assign(defaultValues, inputValues) as Options;
  const upDownDir = [
    {label:'All', value: null},
    {label:'UP', value: StockDirectionType.UP},
    {label:'DOWN', value: StockDirectionType.DOWN}
  ];
  const onSubmit = async (optsIn: Options, actions: any) => {
    const opts = UtilsHelper.createCopy(optsIn);
    opts.data.alertIds = opts.data.alertIds?.length ?
           (opts.data.alertIds as any as string).split(',').map(v=>+v)||null as any : null as any;

    for (const [k,vIn] of Object.entries(opts.data)) {
      let delKey = false;
      if (vIn===null) {
        delKey = true;
      } else
      if (Array.isArray(vIn)) {
        let v = vIn as any[];
        if (v.length===0) {
          delKey = true;
        } else {
          for ( const [aIndex,av] of Array.from(v.entries()).sort((a,b)=>b[0]-a[0])) {
            let isEmpty = true;
            const vals = typeof av === 'object' ? Object.values(av): [av];
            for (const avk of vals) {
              if (avk!==null) {
                isEmpty = false;
              }
            }

            if (isEmpty) {
              v = v.filter((v,index)=>index!==aIndex);
              // console.debug({aIndex,av,vals,isEmpty,k,v});
            }
          }
          if (v.length===0) {
            delKey = true;
          }
        }
      }
      if (delKey) {
        // @ts-ignore
        delete opts.data[k];
      }
    }

    switch(actionType.current) {
      case 'submit': await onClick(opts, submitMode); break;
      case 'analyze': await analyzeReport(opts); break;
      case 'download': await downloadReport(opts); break;
    }


    actions.setSubmitting(false);
  }
  if (initVals?.data?.alertIds) {
    // @ts-ignore
    initVals.data.alertIds = initVals.data.alertIds.join(',');
  }

  return (
    <div className="activation-combo-form">
      <div className="page-title mb-3">Activation Combo Setup</div>

      <Formik initialValues={initVals} enableReinitialize onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue, isSubmitting }) => {
          return <Form>
            <div className="d-flex justify-content-end align-items-end gap-1">

            </div>
            <div className="d-flex justify-content-between align-items-end ">
              <div className="form-group">
                <FieldWithError size={50} errors={errors} touched={touched} fieldName="name" label="Name"  />
              </div>
            </div>
            <div>&nbsp;</div>
            <div className="d-flex justify-content-between align-items-end gap-4">
              <div className="d-flex flex-column gap-4 align-top">
                <div className="form-group">
                  <FieldWithError errors={errors} touched={touched} fieldName="data.alertIds" label="Alert Ids"  />
                </div>
                <div className="form-group">
                  <DropDownGeneric multiple={true} options={symbols.map(v=>{return {label:v.name,value:v.id}})} label="Symbols" name="data.symbolIds"/>
                </div>
                <div className="form-group">
                  <DropDownGeneric options={upDownDir} label="Alert Dir" name="data.alertDirection"/>
                </div>
                <div className="form-group">
                  <SignalAlertCategoriesDropDown label={'Alert Cats '} multiple={true} name="data.alertCategory"/>
                </div>
                {scoreFieldList.map(sName=>{
                  return (<div className="form-group">
                    <FormCondition name={`data.${sName}`} label={sName} values={valuesScores}/>
                  </div>)
                })}
                {tradeFieldList.map(tName=>{
                  return (<div className="form-group">
                    <DropDownGeneric name={`data.${tName}`} label={tName + ' (>=)'} options={valuesTrades}/>
                  </div>)
                })}

                <div className="form-group">
                  <DropDownGeneric className={"horizontal"}  name="data.alertHours" multiple={true} label="Hours" options={[10,11,12,13,14,15].map(v=>{return {label:v,value:v}})}/>
                </div>
              </div>
              <div className="d-flex flex-column gap-2">
              <>
                {[
                  Criteria.CDL_COLOR, Criteria.PREV_CDL_COLOR
                ].map(c=><DropDownGeneric name={`data.${c}`} label={c} options={[null,1,-1].map(v=>{return {label:v||'All',value:v}})}/>)}

                {[
                  Criteria.CONSECUTIVE_COLORS
                ].map(c=><DropDownGeneric name={`data.${c}`} label={c} options={[null,1,2,3,4,5,6,7,8,9,10].map(v=>{return {label:v||'All',value:v}})}/>)}

                {[
                  Criteria.STOCK_GAP_LAST_10_POINTS,
                  Criteria.STOCK_GAP_LAST_2_DAYS_NEXT_DAY,
                ].map(c=><DropDownGeneric name={`data.${c}`} label={c} options={valuesScores}/>)}

                {[
                  Criteria.SMA120_DIST_GROUP,
                  Criteria.VWAP_DIST_GROUP,
                  Criteria.EMA6_DIST_GROUP,
                  Criteria.EMA12_DIST_GROUP,
                  Criteria.EMA26_DIST_GROUP,
                  Criteria.EMA120_DIST_GROUP,
                  Criteria.CDL_COLOR_GROUP,
                  Criteria.PREV_CDL_COLOR_GROUP,
                  Criteria.STOCK_GAP_LAST_10_POINTS_GROUP,
                  Criteria.STOCK_GAP_LAST_5_POINTS_GROUP,
                  Criteria.STOCK_GAP_LAST_2_DAYS_NEXT_DAY_GROUP,
                  Criteria.CONSECUTIVE_COLORS_GROUP,
                  Criteria.EMA6_DIST_NEXT_DAY_GROUP,
                  Criteria.STOCK_GAP_OPEN_PREV_CLOSE_NEXT_DAY_GROUP,
                  Criteria.HIGH_LOW_NEXT_DAY_GROUP,
                  Criteria.VOLUME_GROUP,
                  Criteria.PREV_VOLUME_GROUP,
                  ].map(c=><DropDownGeneric className={'horizontal'} multiple={true} name={`data.${c}`} label={c} options={[2,1,-1,-2].map(v=>{return {label:v||'All',value:v}})}/>)
                }
              </>
              </div>
            </div>
            <div>&nbsp;</div>
            <div className="d-flex justify-content-center align-items-center gap-3">
              <button type="submit" className="btn btn-primary p-2"  onClick={()=>{actionType.current='analyze'}} >Analyze</button>
              <button type="submit" className="btn btn-primary p-2"  onClick={()=>{actionType.current='download'}}>Download</button>

              <div style={{width:'200px'}}></div>

              {isSubmitting && <Spinner minHeight={50} />}
              {isEditing ?
               <div className="d-flex gap-2">
                 <button type="submit" onClick={() => {actionType.current='submit'; setSubmitMode(SubmitModeEnum.UPDATE)}} className="btn btn-primary p-2" disabled={isSubmitting}>Update</button>
                 <button type="submit" onClick={() => {actionType.current='submit'; setSubmitMode(SubmitModeEnum.SAVE_AS_NEW)}} className="btn btn-primary p-2" disabled={isSubmitting}>Save As New</button>
               </div>
                         :
               <button type="submit" onClick={() =>  {actionType.current='submit';setSubmitMode(SubmitModeEnum.CREATE)}} className="btn btn-primary p-2" disabled={isSubmitting}>Create New</button>
              }


            </div>

          </Form>
        }}

      </Formik>
      {filterResult && <>
        <div className="d-flex gap-2">
          <div><b>Score</b>: {MathEx.round(filterResult.scoreWAvg*100, 4)}%</div>
          <div><b>Trades</b>: {filterResult.trades}</div>
          <div><b>Uniq Dates</b>: {filterResult.uniqDays}</div>
        </div>
      </>}
      <div>&nbsp;</div>
    </div>
  )
};
