import React from 'react'
import {StrategyProfitModel as model} from 'models/strategy-profit.model'
import {DropDown} from 'components/common/form/DropDown';
import {
  StockDirectionType as SignalDirectionType,
  SignalItem,
  SignalOptions,
  StockSignalsEnum,
  StockSignalsHelper,
  MathEx
} from 'predictagram-lib';
import {AnalysisFilters} from 'components/common/analysis/analysis.filters';
import {TradeModel} from 'models/trade.model';
import {Trash} from 'react-bootstrap-icons';
import {DropDownGeneric} from "../../../common/form/DropDownGeneric";

interface ISignalSetupProps {
  signalItem: SignalItem,
  index: number,
  errors: any,
  touched: any,
  remove: any,
  setupName: string,
  setFieldValue: any,
  values: any,
  onOpenSignalDirection?: (direction: "up" | "dn") => void,
  submitForm?: any,
}

export const SignalSetupRow: React.FunctionComponent<ISignalSetupProps> = ({
  signalItem,
  index,
  errors,
  touched,
  remove,
  setupName,
  setFieldValue,
  values,
  onOpenSignalDirection,
  submitForm
}) => {

  const signalType: StockSignalsEnum = signalItem.type as StockSignalsEnum;
  const signalSelected = signalItem.type as string || "";
  const signalsList = new Map(StockSignalsHelper.signalsMap());
  const signalRepeatIntervalSecs = AnalysisFilters.SignalRepeatTypes;
  const sgsOpts:Map<StockSignalsEnum, {options:SignalOptions}> = new Map([
    [StockSignalsEnum.PREDS_CONSENSUS,               {options: {durationSecs: 300, minCount: 2,}}],
    [StockSignalsEnum.SIGNALS_PREV_ClOSE_LINE_STOCK, {options: {priceRate: 0.001}}],
    [StockSignalsEnum.SIGNALS_STOCK_GAP, {options:{
      stockGapIntervalSecs: model.optionsDefault.stockGapIntervalSecs,
      stockGapRate: model.optionsDefault.stockGapRate
    }}],
    [StockSignalsEnum.SIGNALS_PRICE_PREV_RANGE_HIGH_LOW, {options:{rangeCount: model.optionsDefault.rangeCount}}],
    [StockSignalsEnum.CDL_CONSECUTIVE_COLORS, {options:{candlesCount: model.optionsDefault.candlesCount}}]
  ]);
  [
    StockSignalsEnum.CDL_VWAP,
    StockSignalsEnum.CDL_SMA120,
    StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_160PCT,
    StockSignalsEnum.CDL_MIN_2H_VD_30M_160PCT,
    StockSignalsEnum.CDL_MAX_2H_VD_30M_160PCT,
    StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_220PCT,
    StockSignalsEnum.CDL_MIN_2H_VD_30M_220PCT,
    StockSignalsEnum.CDL_MAX_2H_VD_30M_220PCT,
    StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_400PCT,
    StockSignalsEnum.CDL_MIN_2H_VD_30M_400PCT,
    StockSignalsEnum.CDL_MAX_2H_VD_30M_400PCT,
  ].forEach(s=>sgsOpts.set(s, {options: {
    candleSizeRate: model.optionsDefault.candleSizeRate,
    volumeRate: model.optionsDefault.volumeRate, // model.optionsDefault.candleSizeRate,
    // volumeSmaCount: model.optionsDefault.volumeSmaCount,// use as default for now
  }}));

  [
    StockSignalsEnum.CDL_CURR_PRICE_CDL_QUARTILE,
    StockSignalsEnum.M5_CDL_CURR_PRICE_CDL_QUARTILE,
    StockSignalsEnum.CDL_OPEN_RANGE_HIGH_LOW,
    StockSignalsEnum.CDL_OPEN_PRICE,
    StockSignalsEnum.CDL_PREV_DAY_CLOSE_PRICE,
    StockSignalsEnum.CDL_SUP_RES_1H_STOCK,
  ].forEach(s=>sgsOpts.set(s, {options: {candleSizeRate: model.optionsDefault.candleSizeRate}}));
  [
    StockSignalsEnum.M5_CDL_CURR_PRICE_1STCDL_BODY,
    StockSignalsEnum.M5_CDL_CURR_PRICE_1STCDL_BODY_2NDCDL_STEM,
  ].forEach(s=>sgsOpts.set(s, {options: {candleSizePriceRate: model.optionsDefault.candleSizePriceRate}}));

  [
    StockSignalsEnum.CDL_CURR_PRICE_1STCDL_BODY,
    StockSignalsEnum.CDL_CURR_PRICE_1STCDL_BODY_2NDCDL_STEM,
  ].forEach(s=>sgsOpts.set(s, {options: {
    candleSizePriceRate: model.optionsDefault.candleSizePriceRate,
    volumeRate: model.optionsDefault.volumeRate,
  }}));
  [
    StockSignalsEnum.CDL_CURR_PRICE_INTRPTCDL_BODY,
    StockSignalsEnum.CDL_CURR_PRICE_INTRPTCDL_BODY_2NDCDL_STEM,
  ].forEach(s=>sgsOpts.set(s, {options: {
      volumeRate: model.optionsDefault.volumeRate,
  }}));

  [
    StockSignalsEnum.SIGNALS_EMA6_CANDLE_DAY,
    StockSignalsEnum.SIGNALS_EMA12_CANDLE_DAY,
    StockSignalsEnum.SIGNALS_EMA26_CANDLE_DAY,
  ].forEach(s=>sgsOpts.set(s, {options: {
    repeatedSignal: model.optionsDefault.repeatedSignal,
    distanceRate: null as any,
  }}));

  [
    StockSignalsEnum.CDL_SUP_RES_1H_STOCK_DIST,
    StockSignalsEnum.CDL_SUP_RES_3D_STOCK_DIST,
  ].forEach(s=>sgsOpts.set(s, {options: {
      distanceRate: null as any,
  }}));

  const candleSizeRate = model.rateRangeAsPct(-1.25, 2.25, 0.25);
  const rateOptions = Array.from(model.rateRangeAsPct(0.1, 3, 0.1)).map(v=>{
    return {value: MathEx.round(v[0], 2), label: v[1]};
  });
  // add default at top
  rateOptions.unshift({value:null as any, label: 'Off'});

  return (
    <tr>
      <td>
        <div style={{ minWidth: "150px" }}>
          <DropDown
            enumMap={signalsList}
            defaultItem={{ key: '', value: 'None' } as any}
            errors={errors} touched={touched} label=""
            name={`${setupName}.signals[${index}][type]`}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const signal = e.target.value as StockSignalsEnum;
              setFieldValue(`${setupName}.signals[${index}][type]`, signal);

              if (sgsOpts.has(signal)) {
                setFieldValue(`${setupName}.signals[${index}][options]`, sgsOpts.get(signal)?.options);
                submitForm && submitForm();
                return;
              }

              setFieldValue(`${setupName}.signals[${index}][options]`, {} as SignalOptions);

            }}
          />
        </div>
      </td>

      {signalSelected === "" ? <td colSpan={8}></td> : <>
        <td key={'cond'}>
          <DropDown
            enumMap={model.signalConditionMap}
            errors={errors}
            touched={touched}
            label=""
            name={`${setupName}.signals[${index}][condition]`}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              // change all rows, not just one
              const items: SignalItem[] = values[setupName].signals;
              for (let i = 0; i < items.length; i++) {
                setFieldValue(`${setupName}.signals[${i}][condition]`, e.target.value);
              }
            }}
          />
        </td>
        <td key={'exclude'}>
          <DropDown
            enumMap={new Map([[false, 'No'], [true, 'Yes'],]) as any}
            errors={errors} touched={touched}
            label=""
            name={`${setupName}.signals[${index}][exclude]`}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const value = e.target.value === "true";
              setFieldValue(`${setupName}.signals[${index}][exclude]`, value);
            }}
          />
        </td>
        <td  key={'repeatSecs'}>
          <DropDown
            enumMap={signalRepeatIntervalSecs}
            errors={errors} touched={touched} label=""
            name={`${setupName}.signals[${index}][repeatSecs]`}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue(`${setupName}.signals[${index}][repeatSecs]`, Number(e.target.value));
            }}
          />
        </td>
        <td  key={'oppositeDirection'}>
          <DropDown
            enumMap={TradeModel.oppositeDir as any}
            errors={errors} touched={touched} label=""
            name={`${setupName}.signals[${index}][oppositeDirection]`}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue(`${setupName}.signals[${index}][oppositeDirection]`, e.target.value === "true");
            }}
          />
        </td>
        <td  key={'repeatTypes'}>
          <DropDown
            enumMap={TradeModel.signalRepeatType}
            multiple={true}
            errors={errors} touched={touched} label=""
            name={`${setupName}.signals[${index}][repeatTypes]`}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const selected = model.toIntArrayOrDefault(Array.from(e.target.selectedOptions).map((o: any) => o.value));
              setFieldValue(`${setupName}.signals[${index}][repeatTypes]`, selected);
            }}
            style={{ height: "50px" }}
          />
        </td>
        <td  key={'directionTypes'}><DropDown
          enumMap={TradeModel.signalDirectionType}
          multiple={true}
          errors={errors}
          touched={touched}
          label=""
          name={`${setupName}.signals[${index}][directionTypes]`}
          style={{ height: "50px" }}
          onChange={(v: any) => {
            const selectedDirectionTypes = model.toIntArrayOrDefault(Array.from(v.target.selectedOptions).map((o: any) => o.value));
            setFieldValue(`${setupName}.signals[${index}][directionTypes]`, selectedDirectionTypes)
            if (onOpenSignalDirection) {
              onOpenSignalDirection(selectedDirectionTypes[0] === SignalDirectionType.DOWN ? "dn" : "up");
            }
          }}
        />
        </td>
        <td  key={'cancellationSecs'}>
          <DropDown
            enumMap={signalRepeatIntervalSecs}
            errors={errors} touched={touched} label=""
            name={`${setupName}.signals[${index}][cancellationSecs]`}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue(`${setupName}.signals[${index}][cancellationSecs]`, Number(e.target.value));
            }}
          />
        </td>

        {/* Options */}
        <td>
          {Object.entries(sgsOpts.get(signalType)?.options||{}).map(([optName, val], index)=>{

            const fullOptionName = `${setupName}.signals[${index}][options][${optName}]`;

            if(optName === 'durationSecs') {
              return <DropDown key={index}
                defaultKey={val}
                enumMap={model.range(1 * 60, 10 * 60, 60, 0, 0.016666)}
                errors={errors} touched={touched} label="Mins"
                name={fullOptionName}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue(fullOptionName, Number(e.target.value));
                  submitForm && submitForm();
                }}
              />
            }

            if (optName === 'minCount') {
              return <DropDown  key={index}
                defaultKey={val}
                enumMap={model.range(1, 10, 1)}
                errors={errors} touched={touched} label="Preds"
                name={fullOptionName}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue(fullOptionName, Number(e.target.value));
                  submitForm && submitForm();
                }}
              />
            }

            if (optName === 'priceRate') {

              return <DropDown  key={index}
                defaultKey={val}
                enumMap={model.signalPrevClosePriceThreshold}
                errors={errors} touched={touched} label="Price % Adjust"
                name={fullOptionName}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue(fullOptionName, parseFloat(e.target.value));
                  submitForm && submitForm();
                }}
              />

            }

            if (optName === 'distanceRate') {
              return <DropDownGeneric  key={index}
                options={[
                  {label:'Off',value:null},
                  ...Array.from(model.signalPrevClosePriceThreshold.entries()).map((v)=>{return {label:v[1],value:v[0]}})
                ]}
                label="Distance %"
                name={fullOptionName}
                className={"form-control"}
              />
            }

            if (optName === 'candleSizeRate') {
              return <DropDown  key={index}
                defaultKey={0.5}
                enumMap={candleSizeRate}
                errors={errors} touched={touched} label="Candle Size %"
                name={fullOptionName}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue(fullOptionName, Number(e.target.value));
                  submitForm && submitForm();
                }}
              />
            }

            if (optName === 'stockGapIntervalSecs') {
              return <DropDown  key={index}
                enumMap={model.aheadTimeSecs}
                errors={errors}
                touched={touched}
                label="Stock Gap Interval"
                name={fullOptionName}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue(fullOptionName, Number(e.target.value));
                  submitForm && submitForm();
                }}
              />
            }

            if (optName === 'stockGapRate') {
              return <DropDown  key={index}
                enumMap={model.signalPrevClosePriceThreshold}
                errors={errors}
                touched={touched}
                label="Stock Gap %"
                name={fullOptionName}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue(fullOptionName, Number(e.target.value));
                  submitForm && submitForm();
                }}
              />
            }

            if (optName === 'rangeCount') {
              return <DropDown  key={index}
                enumMap={model.range(1, 60, 1)}
                errors={errors} touched={touched} label="Range Count"
                name={fullOptionName}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue(fullOptionName, Number(e.target.value));
                  submitForm && submitForm();
                }}
              />
            }

            if (optName === 'candlesCount') {
              return <DropDown  key={index}
                defaultKey={2}
                enumMap={model.range(2, 10, 1)}
                errors={errors} touched={touched}
                label="Candles Count"
                name={fullOptionName}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue(fullOptionName, Number(e.target.value));
                  submitForm && submitForm();
                }}
              />
            }

            if (optName === 'candleSizePriceRate') {
              return <DropDown  key={index}
                defaultKey={0.001}
                enumMap={model.signalPrevClosePriceThreshold}
                errors={errors}
                touched={touched}
                label="Candle Size Price %"
                name={fullOptionName}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue(fullOptionName, Number(e.target.value));
                  submitForm && submitForm();
                }}
              />
            }

            if (optName === 'repeatedSignal') {
              return <DropDownGeneric  key={index}
                options={[{label:'No',value:false},{label:'Yes',value:true}]}
                // errors={errors}
                // touched={touched}
                label="Repeated Signal"
                name={fullOptionName}
                className={"form-control"}
              />
            }

            if (optName === 'volumeRate') {
              // @NOTE: pair both together for now
              return <DropDownGeneric  key={index} options={rateOptions} className={"form-control"} label="Volume Rate" name={fullOptionName}/>
            }
            // @TODO: use 7 in backend by default for now
            // if (optName === 'volumeSmaCount') {
            //   return <div style={{display:'none'}}>
            //       <DropDownGeneric defaultValue={model.optionsDefault.volumeSmaCount} options={[{label: model.optionsDefault.volumeSmaCount?.toString(), value: model.optionsDefault.volumeSmaCount}]} name={fullOptionName}  label={'SMA Count'}/>
            //   </div>
            //
            // }

            // default
            return <></>
          })}

        </td>
      </>}

      <td className="align-self-center text-center">
        <div role="button" onClick={() => remove(index)}><Trash /></div>
      </td>
    </tr>
  );
}
