import React, {useState} from "react";
import {Spinner} from "../../../common/Spinner";

export function ButtonSpinner<T>(props:{
  label:string,
  load:()=>Promise<T>
  type?:'button'|'submit',
}) {
  const buttonType = props.type||'button';
  const [loading,setLoading] = useState<boolean>(false)
  const load = async() =>{
    try {
      setLoading(true);
      return await props.load();
    } finally {
      setLoading(false);
    }
  }
  return (<>
    {!loading &&
     <button type={buttonType} className="btn btn-primary" onClick={()=>load()}>{props.label}</button>
    }
    {loading&&<Spinner isSmall={true} minHeight={10}/>}
  </>)
}
