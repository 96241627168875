import React, { useContext } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Url } from "_constants";
import { AccountContext } from "components/common";

const Navigation: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const accountContext = useContext(AccountContext);
 
  const logout = () => {
    accountContext?.logout();
    navigate(Url.ADMIN_HOME_PAGE);
  };

  // const onClickMyAccount = () => {
  //   navigate(Url.ADMIN_PROFILE);
  // };

  const onClickLogin = () => {
    navigate(Url.ADMIN_LOGIN);
  }

  return (
    <Navbar expand="lg">
      <Navbar.Brand href={Url.ADMIN_HOME_PAGE}>Predictagram Admin</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="justify-content-end w-100">
          <Nav.Item>
            <div className="btn-toolbar d-flex gap-2">
              {!accountContext?.account ? (
                <button type="button" className="btn btn-primary mx-2" onClick={onClickLogin}>Login</button>
              ) : (
                <>
                  <Nav.Link href={Url.ADMIN_PREDICTIONS}>Predictions</Nav.Link>
                  <Nav.Link href={Url.ADMIN_CONTEST}>Contests</Nav.Link>

                  <NavDropdown title="Auto Trading" id="autotrade-dropdown">
                    <Nav.Link className="text-14" href={Url.ADMIN_AUTOTRADE_SETUPS}>Trade Setups</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_AUTOTRADE_SYMBOL_LIMITS}>Stock Symbol Limits</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_AUTOTRADE_SETUP}>New Trade Setup</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_AUTOTRADE_TRADES}>All Trades</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_AUTOTRADE_TRADES_GROUPBY_SETUP}>Trades (Grouped by Setup)</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_AUTOTRADE_TRADES_GROUPBY_SECURITY}>Trades (Grouped by Security)</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_AUTOTRADE_TRADES_GROUPBY_SCORE}>Trades (Grouped by Score)</Nav.Link>
                    <Nav.Link className="text-14" href={Url.USER_MULTI_COMPARE_TOOL}>Multi-Compare Tool</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_AUTOTRADE_ACTIVATION_COMBOS}>Activation Combos</Nav.Link>
                  </NavDropdown>

                  <NavDropdown title="Users" id="users-dropdown">
                    <Nav.Link className="text-14" href={Url.ADMIN_REGISTRATIONS}>Registrations</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_USERS_STATS}>Users Stats</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_TICKETS}>Tickets</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_STREAK_STATS}>Leagues</Nav.Link>
                  </NavDropdown>

                  <NavDropdown title="League" id="league-dropdown">
                    <Nav.Link className="text-14" href={Url.ADMIN_LEAGUES}>Leagues (Old)</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_BADGES}>Badges</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_LEAGUE_SEASONS}>Seasons</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_LEAGUE_TEAMS}>Teams</Nav.Link>
                  </NavDropdown>

                  <NavDropdown title="Analytics" id="analytics-dropdown">
                    <Nav.Link className="text-14" href={Url.ADMIN_STOCK_HISTORY}>Stock History</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_ANALYSIS_STRATEGY}>Analysis</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_CUMULATIVE_DETAIL}>Cumulative Detail</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_CUMULATIVE_DETAIL_DAY}>Cumulative Detail Day</Nav.Link>
                    {/*<Nav.Link className="text-14" href={Url.ADMIN_SIGNALS}>Signals</Nav.Link>*/}
                    <Nav.Link className="text-14" href={Url.ADMIN_SIGNAL_ALERTS}>Signal Alerts</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_SIGNAL_ALERT_COMBINED_REPORT}>Alert Combined Report</Nav.Link>
                    <Nav.Link className="text-14" href={Url.ADMIN_SAVED_REPORTS}>Saved Reports</Nav.Link>
                  </NavDropdown>

                  <Nav.Link href={Url.ADMIN_TRADEGRAM_PENDING_VERIFICATION}>Verify Trades</Nav.Link>
                  {/* <Nav.Link href={Url.ADMIN_PROFILE} onClick={onClickMyAccount}>My Account</Nav.Link> */}
                  <Nav.Link href={Url.ADMIN_HOME_PAGE} onClick={logout}>Sign Out</Nav.Link>
                </>
              )}
            </div>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export { Navigation as AdminNavigation};
